import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { clean, explosion, timeChange, createWorld } from '../Matter';

const Infos = () => {
    const getYear = new Date().getFullYear();

    useEffect(() => {
        createWorld()
    }, [])

    return (
        <>
            <div id="neg" className="blackout" hidden></div>
            <div id="canvas" className="canvas"></div>
            <div id='infos-bloc' className='infos-bloc' >
                <div className="infos-emails-bloc" onClick={timeChange}>
                    Get in touch :
                    <br />
                    <a className="infos_link" href="mailto:fdecombat@yahoo.fr">fdecombat@yahoo.fr</a>
                </div>
                <div className="infos-clients-bloc" onClick={explosion}>
                    <br />
                    clients:
                    <br />
                    The New York Times, Nike, Bloomberg, Financial Times, Ace & Tate, Le Monde, Zeit Magazine, L'Equipe, Frieze, L'Obs, Society...
                </div>
                <div className="infos-copyright-bloc"
                    onClick={() => {
                        let el = document.getElementById('neg')
                        el.hidden = !el.hidden ? true : false
                    }}
                >
                    Decombat Félix
                    <span className="copyright-info"> ©</span> {getYear}
                    <br />
                    All rights reserved
                </div>
                <div className="infos-home-bloc">
                    <Link
                        className="infos_link"
                        to="/"
                        onClick={() => {
                            clean()
                            document.getElementById('canvas').hidden = true
                            document.getElementById('canvas').style.pointerEvents = 'none';
                        }}
                    >gallery</Link>
                </div>
            </div >
        </>
    );
}


export default Infos;