import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Render from './Render';
import Infos from './Components/Infos';
import './Assets/Styles/App.css';
import './Assets/Styles/Filters.css';
import './Assets/Styles/List.css';
import "./Assets/Styles/Infos.css";
import "./Assets/Styles/Extra/Cross.css";
import "./Assets/Styles/Slider.css";

function App() {

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Render />} />
          <Route path="/Infos" element={<Infos />} />
        </Routes>
      </Router>
    </>
  )
}


export default App;