import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { installTwicPics } from '@twicpics/components/react'
import '@twicpics/components/style.css'

installTwicPics({
    domain: 'https://fdecombat.twic.pics'
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);