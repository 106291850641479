import React, { useEffect, useState } from 'react';
import filt from '../Assets/Filters'
import Draggable from 'react-draggable';

const tags = document.createElement('div');
tags.className = "filters_tags";

const Filters = props => {

    const [filtres, setFiltres] = useState('');

    const select = props.setSelection

    useEffect(() => {
        setFiltres(
            filt.map((image, key) => (
                <span
                    key={key}
                    id={`image${key}`}
                    className='filters_title'
                    onClick={e => { select(e.target.innerText) }}
                >{image}</span>
            ))
        );
    }, [select])

    return (
        <Draggable handle="strong">
            <div
                id="filters"
                className="filters_component" >
                <strong>
                    <div className="filters_drag_button"></div>
                </strong>
                <span
                    className="filters_cross"
                    onClick={() => {
                        let obj = document.getElementById('filters')
                        obj.hidden = !obj.hidden ? true : false
                    }}
                >
                    <span className="close close_filters"></span>
                </span>
                <div id='filters_bloc'
                    className='filters_bloc'>
                    {filtres}
                </div>
            </div>
        </Draggable >
    )

}

export default Filters;