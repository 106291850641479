import Matter from 'matter-js'

import tortue from './Matter/tortue.png'
import roulade from './Matter/roulade.png'
import vomi from './Matter/vomi.png'
import gorille from './Matter/gorille.png'
import fakir from './Matter/fakir.png'


var Engine = Matter.Engine,
    Render = Matter.Render,
    World = Matter.World,
    Bodies = Matter.Bodies,
    MouseConstraint = Matter.MouseConstraint,
    Mouse = Matter.Mouse,
    Runner = Matter.Runner,
    Body = Matter.Body,
    Composite = Matter.Composite,
    Common = Matter.Common,
    Composites = Matter.Composites

// Adapt the size to the screen 
let width = window.innerWidth - 70,
    height = width / 3 * 2
if (height > window.innerHeight - 70) {
    height = window.innerHeight - 70
    width = height / 2 * 3
}
var scale = 0.15 + width / 5000,
    scale2 = 0.05 + width / 5000,
    scale0 = 0.25 + width / 5000,
    out = 1

// create engine
var engine = Engine.create()
var world = engine.world

var explosion = function () {
    if (engine && engine.world) {
        var bodies = Composite.allBodies(engine.world);
        // engine.timing.timeScale = 1
        for (var i = 4; i < bodies.length; i++) {
            var body = bodies[i];

            // scale force for mass and time applied
            var forceMagnitude = (0.05 * body.mass / 1.2);
            var x = (forceMagnitude + Common.random() * forceMagnitude) * Common.choose([1, -1]),
                y = -forceMagnitude + Common.random() * -forceMagnitude
            // apply the force over a single update
            if (body.speed < 5)
                Body.applyForce(body, body.position, {
                    x: x,
                    y: y
                });
        }
    }
};

const createWorld = () => {
    // create renderer
    var render = Render.create({
        element: document.getElementById('canvas'),
        engine: engine,
        options: {
            width: window.innerWidth,
            height: window.innerHeight,
            wireframes: false,
            background: 'transparent'
        }
    });

    Render.run(render);

    // create runner
    var runner = Runner.create();
    Runner.run(runner, engine);

    // add walls
    Composite.add(world, [
        Bodies.rectangle(window.innerWidth / 2, -25, window.innerWidth, 50, { isStatic: true }),
        Bodies.rectangle(window.innerWidth / 2, window.innerHeight + 25, window.innerWidth, 50, { isStatic: true }),
        Bodies.rectangle(window.innerWidth + 25, window.innerHeight / 2, 50, window.innerHeight, { isStatic: true }),
        Bodies.rectangle(-25, window.innerHeight / 2, 50, window.innerHeight, { isStatic: true })
        // rock,
        // elastic
    ]);

    // Composite.add(world, Composites.stack(200, 200, 1, 5, 20, 10, function (x, y) {
    //     return Bodies.rectangle(
    //         Common.random(122 / 2, width - 122 / 2), Common.random(217 / 2, height - 217 / 2), 122 * scale, 217 * scale,
    //         {
    //             render: { sprite: { texture: tortue, xScale: scale, yScale: scale } },
    //             chamfer: 5,
    //             restitution: 0.5,
    //             frictionAir: 0.001,
    //             x: 0.5,
    //             y: 0.5
    //         }
    //     );
    // }));

    Composite.add(world, Composites.stack(10, 10, 4, 4, 0, 0, function (x, y) {
        if (Common.random() < 0.1) {
            return Bodies.rectangle(
                Common.random(400 / 2, width - 400 / 2), Common.random(350 / 2, height - 350 / 2), 400 * scale, 350 * scale,
                {
                    render: { sprite: { texture: tortue, xScale: scale, yScale: scale } },
                    chamfer: 5,
                    restitution: 0.5,
                    frictionAir: 0.001,
                    x: 0.5,
                    y: 0.5
                }
            );
        } else if (Common.random() < 0.2) {
            return Bodies.circle(
                Common.random(567 / 2, width - 567 / 2), Common.random(544 / 2, height - 544 / 2), 550 / 2 * scale0,
                {
                    render: { sprite: { texture: roulade, xScale: scale0, yScale: scale0 } },
                    chamfer: 5,
                    restitution: 0.5,
                    frictionAir: 0.001,
                    x: 0.5,
                    y: 0.5
                }
            );
        } else if (Common.random() < 0.3) {
            return Bodies.rectangle(
                Common.random(425 / 2, width - 425 / 2), Common.random(568 / 2, height - 568 / 2), 425 * scale2, 568 * scale2,
                {
                    render: { sprite: { texture: vomi, xScale: scale2, yScale: scale2 } },
                    chamfer: 5,
                    restitution: 0.5,
                    frictionAir: 0.001,
                    x: 0.5,
                    y: 0.5
                }
            );
        } else if (Common.random() < 0.4) {
            return Bodies.rectangle(
                Common.random(283 / 2, width - 283 / 2), Common.random(306 / 2, height - 306 / 2), 283 * scale2, 306 * scale0,
                {
                    render: { sprite: { texture: gorille, xScale: scale0, yScale: scale0 } },
                    chamfer: 5,
                    restitution: 0.5,
                    frictionAir: 0.001,
                    x: 0.5,
                    y: 0.5
                }
            );
        }
        else {
            return Bodies.rectangle(
                Common.random(227 / 2, width - 227 / 2), Common.random(535 / 2, height - 535 / 2), 227 * scale2, 535 * scale2,
                {
                    render: { sprite: { texture: fakir, xScale: scale2, yScale: scale2 } },
                    chamfer: 5,
                    restitution: 0.5,
                    frictionAir: 0.001,
                    x: 0.5,
                    y: 0.5
                }
            );
        }
    }))

    document.getElementById('canvas').style.pointerEvents = 'auto';
    engine.gravity.y = 0

    // missile (angry bird)
    // var   halfW = window.innerWidth / 2,
    // halfH = window.innerHeight / 2
    // var rock = Bodies.circle(halfW, halfH, 20, { density: 0.004, render: { sprite: { texture: fav } } }),
    //     elastic = Constraint.create({
    //         pointA: { x: halfW, y: halfH },
    //         bodyB: rock,
    //         stiffness: 0.05,
    //         render: { type: 'line', anchors: false, strokeStyle: { color: 'black' } }
    //     })
    // Events.on(engine, 'afterUpdate', function () {
    //     if (mouseConstraint.mouse.button === -1 && (rock.position.x > halfW + 20 || rock.position.y < halfH - 20)) {
    //         rock = Bodies.circle(halfW, halfH, 20, { density: 0.004, render: { sprite: { texture: fav } } })
    //         Composite.add(engine.world, rock);
    //         elastic.bodyB = rock;
    //     }
    // });

    // add mouse control

    var mouse = Mouse.create(render.canvas),
        mouseConstraint = MouseConstraint.create(engine, {
            mouse: mouse,
            constraint: {
                stiffness: 0.2,
                render: {
                    visible: false
                }
            }
        });

    Composite.add(world, mouseConstraint);

    // keep the mouse in sync with rendering
    render.mouse = mouse;

    // fit the render viewport to the scene
    Render.lookAt(render, {
        min: { x: 0, y: 0 },
        max: { x: window.innerWidth, y: window.innerHeight }
    });

    // trigger explosion when the page is charging
    explosion()
}

var int = null

var timeChange = function () {
    if (engine.world) {
        var lastTime = 0
        clearInterval(int)
        if (out !== null) {
            int = setInterval(() => {
                out = null
                lastTime -= 0.01
                engine.timing.timeScale += lastTime
                if (engine.timing.timeScale < 0.1) {
                    out = 1
                    setTimeout(() => engine.timing.timeScale = 1, '1000')
                    explosion()
                    clearInterval(int)
                }
            }, 90)
        }
    }
}

const addElement = () => {
    if (engine.world) {
        document.getElementById('canvas').style.pointerEvents = 'auto'
        var dwolf = Bodies.rectangle(
            300, 300 - 230, 122 * scale, 217 * scale,
            {
                render: { sprite: { texture: tortue, xScale: scale, yScale: scale } },
                chamfer: 5,
                restitution: 1,
                frictionAir: 0.001,
                x: 0.5,
                y: 0.5
            }
        )
        World.add(world, [dwolf])
    }
}

const clean = () => {
    if (Engine) {
        // Events.off(engine, 'afterUpdate')
        // Matter.Engine.clear(engine)
        document.getElementById('canvas').style.pointerEvents = 'none'
        Matter.World.clear(engine.world)
    }
}

export { addElement, clean, explosion, timeChange, createWorld }
