import React, { useState } from 'react'
import { Link } from "react-router-dom";
import List from './Components/Images-List'
import Filters from './Components/Images-Filters.js'

function Render() {

    const [selection, setSelection] = useState('Selected Projects')

    return (
        <>
            <div className="titres" id="titres">
                <span className="images-infos" id="image-infos" hidden></span>
                <span className="images-infos-pages tourne" id="image-infos-pages" ></span>
                <div className="titres_bloc">
                    <span
                        className="link-button"
                        onClick={() => {
                            let el = document.getElementById('filters')
                            el.hidden = !el.hidden ? true : false
                        }}
                    >Filtres</span>
                </div>
                <div className="titres_bloc">
                    <Link
                        to="/Infos"
                        className="link-button"
                    >Infos</Link>
                </div>
            </div >
            <div id="elem" >
                <Filters setSelection={setSelection} />
                <List selection={selection} />
            </div>
        </>
    )
}

export default Render